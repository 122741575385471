// Importez React et d'autres composants nécessaires
import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import QuiSommesNous from "./components/QuiSommesNous/QuiSommesNous";
import Accueil from "./components/Accueil/Accueil";
import PresentationFTLM from "./components/PresentationFTLM/PresentationFTLM";
import Événements from "./components/Événements/Événements";
import Publications from "./components/Publications/Publications";
import Webinaires from "./components/Webinaires/Webinaires";
import Brochures from "./components/Brochures/Brochures";
import Contact from "./components/Contact/Contact";
import JoinForm from "./components/JoinForm/JoinForm"; // Import du composant JoinForm
import Comments from "./components/Comments/Comments";
import LoginPage from "./components/LoginPage/LoginPage";
import i18n from "./i18n";
import ForgotPasswordPage from "./components/ForgotPasswordPage/ForgotPasswordPage";
import VerifyResetCodePage from "./components/VerifyResetCodePage/VerifyResetCodePage";
import RegisterPage from "./components/RegisterPage/RegisterPage";
import { AuthProvider } from "./components/auth/AuthContext";

import AdminDashboard from "./components/Dashboard/AdminDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import axios from "axios";

import RegisterAdmin from "./components/RegisterPage/RegisterAdmin";
// Composant principal App
function App() {
  // Définissez userId avec une valeur appropriée (vous devrez peut-être récupérer cette valeur depuis votre backend)
  // À remplacer par la valeur réelle ou obtenue dynamiquement

 /* const logVisitor = async () => {
    try {
      await axios.post("http://ftlm.org.tn/api/visitors/log");
    } catch (error) {
      console.error("Error logging visitor", error);
    }
  };
  useEffect(() => {
    logVisitor();
  }, []);*/

  return (
    <Router  >
      <div className="App">
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/accueil" element={<Accueil renderHero={true} />} />
            <Route
              path="/quisommesnous"
              element={<QuiSommesNous renderHero={true} />}
            />
            <Route
              path="/PresentationFTLM"
              element={<PresentationFTLM renderHero={true} />}
            />
            <Route
              path="/Événements"
              element={<Événements renderHero={true} />}
            />
            <Route
              path="/Publications"
              element={<Publications renderHero={true} />}
            />
            <Route
              path="/Webinaires"
              element={<Webinaires renderHero={true} />}
            />
            <Route
              path="/Brochures"
              element={<Brochures renderHero={true} />}
            />
            <Route path="/Contact" element={<Contact renderHero={true} />} />
            {/* Ajoutez une route pour la page du formulaire JoinForm */}
            <Route path="/RejoignezNous" element={<JoinForm renderHero={true}/>} />

                                                       
            <Route path="/comments" element={<Comments />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="/verify-reset-code"
              element={<VerifyResetCodePage />}
            />
            <Route path="/register" element={<RegisterPage renderHero={true} />} />
            <Route path="/registerAdmin" element={<RegisterAdmin />} />

            {/* <Route path="/register admin" element={<LogReg />} /> */}
            <Route
              path="/Dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
}

// Exportez le composant App
export default App;
