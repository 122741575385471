import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './JoinForm.css';

const JoinForm = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    fonction: '',
    adresse: '',
    gouvernorat: '',
    gsm: '',
    email: '',
    facebook: '',
    whatsapp: '',
    parraineePar: '',
    entreprise: '',
    secteurActivite: '',
    produitsServices: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Envoyer les données à l'adresse email spécifiée
    const email = 'presidente@ftlm.org.tn';
    const subject = 'Nouvelle demande d\'adhésion au FTLM';
    const body = `
      Nouvelle demande d'adhésion au FTLM :

      Nom: ${formData.nom}
      Prénom: ${formData.prenom}
      Fonction: ${formData.fonction}
      Adresse: ${formData.adresse}
      Gouvernorat: ${formData.gouvernorat}
      GSM: ${formData.gsm}
      E-mail: ${formData.email}
      Facebook: ${formData.facebook}
      WhatsApp: ${formData.whatsapp}
      Parrainée par: ${formData.parraineePar}
      Nom de l'entreprise: ${formData.entreprise}
      Secteur(s) d'activité: ${formData.secteurActivite}
      Produits ou services fournis: ${formData.produitsServices}
      
      Conditions & Mode d'adhésion:
      - Remplir la fiche d'adhésion
      - Photo d'identité
      - Paiement cotisation annuelle
    `;

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Afficher une notification
    toast.success('Votre demande d\'adhésion a été envoyée avec succès!', {
      position: 'top-right',
      autoClose: 5000, // Fermer automatiquement après 5 secondes
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div>
       
       <div className='Événements' id='Événements'>
     
      <div className='kmmmp' id='kmmmp'>
        <ToastContainer />
      <h2>Fiche d'adhésion</h2>
      <form onSubmit={handleSubmit}>
        <label>Nom :</label>
        <input type="text" name="nom" value={formData.nom} onChange={handleChange} required />

        <label>Prénom :</label>
        <input type="text" name="prenom" value={formData.prenom} onChange={handleChange} required />

        <label>Fonction :</label>
        <input type="text" name="fonction" value={formData.fonction} onChange={handleChange} required />

        <label>Adresse :</label>
        <input type="text" name="adresse" value={formData.adresse} onChange={handleChange} required />

        <label>Gouvernorat :</label>
        <input type="text" name="gouvernorat" value={formData.gouvernorat} onChange={handleChange} required />

        <label>GSM :</label>
        <input type="text" name="gsm" value={formData.gsm} onChange={handleChange} required />

        <label>E-mail :</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />

        <label>Facebook :</label>
        <input type="text" name="facebook" value={formData.facebook} onChange={handleChange} />

        <label>WhatsApp :</label>
        <input type="text" name="whatsapp" value={formData.whatsapp} onChange={handleChange} />

        <label>Parrainée par :</label>
        <input type="text" name="parraineePar" value={formData.parraineePar} onChange={handleChange} />

        <label>Nom de l'entreprise :</label>
        <input type="text" name="entreprise" value={formData.entreprise} onChange={handleChange} />

        <label>Secteur(s) d'activité :</label>
        <select name="secteurActivite" value={formData.secteurActivite} onChange={handleChange} required>
          <option value="">Sélectionnez le secteur d'activité</option>
          <option value="Commerce">Commerce</option>
          <option value="Industrie">Industrie</option>
          <option value="Service">Service</option>
          <option value="Artisanat">Artisanat</option>
        </select>

        <label>Produits ou services fournis :</label>
        <textarea name="produitsServices" value={formData.produitsServices} onChange={handleChange} required />

        {/* Conditions & Mode d'adhésion */}
        <p>Conditions & Mode d'adhésion :</p>
        <ul>
          <li>Remplir la fiche d'adhésion</li>
          <li>Paiement cotisation annuelle NB : Envoyer les documents demandés à : mohamedrassem.hammami1@gmail.com</li>
        </ul>

        <button type="submit">Envoyer</button>
      </form>
    </div>
    </div>
    </div>
  );
};

export default JoinForm;
