import React, { useEffect, useState } from 'react';
import './Webinaires.css';
import Hero from '../Hero/Hero';
import axios from 'axios';

const Webinaires = ({ renderHero }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [webinaires, setWebinaire] = useState([]);
  const [loading, setLoading] = useState(true); // Ajouter l'état de chargement
  const itemsPerPage = 2; // Nombre d'images par page
  const protocol = window.location.protocol;

  const fetchWebinaires = async () => {
    setLoading(true); // Démarrer le chargement
    try {
 
      const response = await axios.get(`${protocol}//ftlm.org.tn/webinaires`);
      const filePromises = response.data.map(async (file) => {
        const blobResponse = await axios.get(
          `${protocol}//ftlm.org.tn/webinaires/${file.id}/download`,
          { responseType: "blob" }
        );
        return {
          ...file,
          data: URL.createObjectURL(blobResponse.data),
        };
      });
      const filesWithBlobUrl = await Promise.all(filePromises);
      setWebinaire(filesWithBlobUrl);
    } catch (error) {
      console.error("Error fetching webinaires", error);
    } finally {
      setLoading(false); // Terminer le chargement
    }
  };

  useEffect(() => {
    fetchWebinaires();
  }, []);

  // Calculer le nombre total de pages
  const totalPages = Math.ceil(webinaires.length / itemsPerPage);

  // Filtrer les webinaires à afficher en fonction de la page actuelle
  const currentItems = webinaires.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="webinaires">
      {renderHero && <Hero />}
      <div className='Publications' id='Publications'>
        <div className='Webinaires' id='Webinaires'>
          {/* Afficher le cercle de chargement si le chargement est en cours */}
          {loading ? (
            <div className="loading-spinner">
              <div className="spinner"></div> {/* Cercle de chargement */}
            </div>
          ) : (
            <>
              <div className="pagination">
                <button onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
                  Précédent
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button key={i} onClick={() => changePage(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                    {i + 1}
                  </button>
                ))}
                <button onClick={() => changePage(currentPage + 1)} disabled={currentPage === totalPages}>
                  Suivant
                </button>
              </div>

              <div className="webinaires-container">
                {currentItems.map((event, index) => (
                  <div key={index} className="webinaire-card">
                    <h1 className="webinaire-title">{event.titre}</h1>
                    <div className="webinaire-details">
                      <p className="webinaire-lien">{event.lien}</p>
                      <img src={event.data} alt={`Webinaire ${index + 1}`} className="webinaire-image" />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Webinaires;
