import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './VerifyResetCodePage.css';

const VerifyResetCodePage = () => {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const protocol = window.location.protocol;

  const handleVerifyCode = async () => {
    try {
      const response = await fetch(`${protocol}//ftlm.org.tn/spring/api/verify-reset-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, token: code, newPassword }),
      });

      if (response.ok) {
        setMessage('Mot de passe réinitialisé avec succès. Vous pouvez maintenant vous connecter.');
        navigate('/login');
      } else {
        setMessage('Code de réinitialisation invalide ou expiré.');
      }
    } catch (error) {
      setMessage('Une erreur est survenue. Veuillez réessayer.');
      console.error('Error during code verification:', error);
    }
  };

  return (
    <div className="verify-reset-code-page">
      <h2>Vérification du Code</h2>
      <label>Code de Réinitialisation *</label>
      <input
        type="text"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        required
      />
      <label>Nouveau Mot de Passe *</label>
      <input
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />
      <button className="verify-code-button" onClick={handleVerifyCode}>
        Vérifier le Code et Réinitialiser le Mot de Passe
      </button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default VerifyResetCodePage;
