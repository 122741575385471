import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoMdDownload } from "react-icons/io";
import { IoTrashOutline } from "react-icons/io5";
import "../../../src/tailwind.css";

const Brochures = () => {
    const [brochures, setBrochures] = useState([]);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const protocol = window.location.protocol;

    useEffect(() => {
        fetchBrochures();
    }, []);
    

    const fetchBrochures = async () => {
        try {
            
            const response = await axios.get(`${protocol}//ftlm.org.tn/brochures`);
            setBrochures(response.data);
        } catch (error) {
            console.error('Error fetching brochures', error);
        }
    };

    const handleDownload = async (id, title) => {
        try {
          
            const response = await axios.get(`${protocol}//ftlm.org.tn/brochures/${id}/download`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${title}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading brochure', error);
        }
    };

    const handleDelete = (id) => {
      
        axios.delete(`${protocol}//ftlm.org.tn/brochures/${id}`)
            .then(() => {
                setBrochures(brochures.filter(message => message.id !== id));
            })
            .catch((error) => {
                console.error('Error deleting message:', error);
            });
    };

    const openUploadModal = () => {
        setShowUploadModal(true);
    };

    const closeUploadModal = () => {
        setShowUploadModal(false);
        setTitle('');
        setFile(null);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUploadSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('file', file);

        try {
            const protocol = window.location.protocol;
            const response = await axios.post(`${protocol}//ftlm.org.tn/brochures`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Brochure uploaded successfully');
            closeUploadModal();
            fetchBrochures(); // Refresh the list after upload
        } catch (error) {
            console.error('Error uploading brochure', error);
            alert('Failed to upload brochure');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className='flex justify-between'>
            <h2 className="text-2xl font-bold mb-4 text-center">Brochures</h2>
            <button
                onClick={openUploadModal}
                className="bg-green-500 text-white px-4 py-2 rounded-md w-1/6 mb-4"
            >
                Add New Brochure
            </button>
            </div>
            <div className="overflow-x-auto text-center">
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b-2 border-gray-300 ">Title</th>
                            <th className="py-2 px-4 border-b-2 border-gray-300 ">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brochures.map((brochure) => (
                            <tr key={brochure.id}>
                                <td className="py-2 px-4 border-b border-gray-300">{brochure.title}</td>
                                <td className="py-2 px-4 border-b border-gray-300 flex justify-center">
                                    <button
                                        onClick={() => handleDownload(brochure.id, brochure.title)}
                                        className="bg-blue-500 w-1/5 text-white px-4 py-2 rounded-md mr-2 flex justify-center gap-2 items-center"
                                    >
                                        <IoMdDownload />
                                        Download
                                    </button>
                                    <button
                                        onClick={() => handleDelete(brochure.id)}
                                        className="bg-red-500 w-1/5 text-white px-4 py-2 rounded-md flex justify-center gap-2 items-center"
                                    >
                                        <IoTrashOutline />
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showUploadModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={closeUploadModal}></div>
                    <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                        <div className="px-4 py-5 sm:p-6">
                            <h2 className="text-lg leading-6 font-medium text-gray-900">Upload Brochure</h2>
                            <form onSubmit={handleUploadSubmit}>
                                <div className="form-group mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Title</label>
                                    <input
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        placeholder="Title"
                                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        required
                                    />
                                </div>
                                <div className="form-group mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Brochure PDF</label>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        required
                                    />
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        onClick={closeUploadModal}
                                        className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded-md"
                                    >
                                        Upload
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Brochures;
