import React, { useState } from 'react';
import axios from 'axios';
import './FileUpload.css'; // Make sure to create this CSS file

const EventForm = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [lieu, setLieu] = useState('');
    const [dateheure, setDateheure] = useState('');
    const [file, setFile] = useState(null);
    const protocol = window.location.protocol;
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('lieu', lieu);
        formData.append('dateHeure', dateheure);
        formData.append('file', file);

        try {
         
            const response = await axios.post(`${protocol}//ftlm.org.tn/evennements`, formData);
            alert(response.data);
        } catch (error) {
            console.error('Error uploading file', error);
            alert('Failed to upload file.');
        }
    };

    return (
        <div className="file-upload-container">
            <h2>Upload File</h2>
            <form onSubmit={handleSubmit} className="file-upload-form">
                <div className="form-group">
                    <label htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Title"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lieu">Lieu</label>
                    <input
                        type="text"
                        id="lieu"
                        value={lieu}
                        onChange={(e) => setLieu(e.target.value)}
                        placeholder="Lieu"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="dateheure">Date and Time</label>
                    <input
                        type="datetime-local"
                        id="dateheure"
                        value={dateheure}
                        onChange={(e) => setDateheure(e.target.value)}
                        placeholder="Date and Time"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="file">File</label>
                    <input
                        type="file"
                        id="file"
                        onChange={handleFileChange}
                        required
                    />
                </div>
                <button type="submit" className="upload-button">Upload</button>
            </form>
        </div>
    );
};

export default EventForm;
