import React, { useEffect } from 'react';
import { IoHome } from 'react-icons/io5';

import { TiMessages } from 'react-icons/ti';

import { MdEvent } from "react-icons/md";
import { GrCatalog } from "react-icons/gr";
import { SiGooglemeet } from "react-icons/si";
import { IoStatsChartOutline } from "react-icons/io5";


import "../../../src/tailwind.css"


import { FaUsers } from "react-icons/fa6";

import { Link } from 'react-router-dom';
import { CiLogout } from 'react-icons/ci';
import { useAuth } from '../auth/AuthContext';
import { jwtDecode } from 'jwt-decode';
const Sidebar = ({ setcontent ,content }) => {
  const { role,token,logout } = useAuth(); // Access role using useAuth hook
  const userRole=jwtDecode(token).role
  const eemail=jwtDecode(token).email

  console.log("*****",userRole)
  console.log("this the role =======>",role);
  console.log("this the token =======>",token);



  useEffect(() => {
    if (userRole === "user") {
      setcontent(4);
    }
  }, [role]);
  return (
    <div className="flex flex-col h-full bg-gray-800 fixed left-0 top-0 w-1/5 ">
      <div className="space-y-3 mt-10">
        
        <div>
          <h2 className="font-medium text-xs md:text-sm text-center text-teal-500">
          {}
          </h2>
          <h2 className="font-medium text-xs md:text-sm text-center text-teal-500">
            {eemail}
          </h2>
          <p className="text-xs text-gray-500 text-center">{userRole === 'admin' ? 'Administrator' : 'User'}</p>
        </div>
      </div>
      <nav className="flex-1 flex flex-col justify-center space-y-4">
        <Link to="/">
          <div className="flex items-center justify-start text-gray-300 hover:bg-gray-700 py-2 px-6 cursor-pointer">
            <IoHome className="mr-4" />
            <span>Home</span>
          </div>
        </Link>
        
       
        
       
        {userRole === 'admin' && (
          <>
            <div
              onClick={() => {
                setcontent(5);
                console.log('hatha 0');
              }}
              className={`flex items-center justify-start text-gray-300 hover:bg-gray-700 py-2 px-6 cursor-pointer ${content===5 && "bg-gray-500"} `}
            >
              <IoStatsChartOutline className="mr-4"/>

              <span>Stats</span>
            </div>
            <div
              onClick={() => {
                setcontent(0);
                console.log('hatha 0');
              }}
              className={`flex items-center justify-start text-gray-300 hover:bg-gray-700 py-2 px-6 cursor-pointer ${content===0 && "bg-gray-500"} `}
            >
              <FaUsers className="mr-4"/>

              <span>Users</span>
            </div>
            <div
              onClick={() => {
                setcontent(1);
                console.log('hatha 1');
              }}
              className={`flex items-center justify-start text-gray-300 hover:bg-gray-700 py-2 px-6 cursor-pointer ${content===1 && "bg-gray-500"} `}
            >
              <TiMessages className="mr-4" />
              <span>Messages</span>
            </div>
            <div
              onClick={() => {
                setcontent(2);
                console.log('hatha 2');
              }}
              className={`flex items-center justify-start text-gray-300 hover:bg-gray-700 py-2 px-6 cursor-pointer ${content===2 && "bg-gray-500"} `}
            >
              <MdEvent className="mr-4" />

              <span>Events</span>
            </div>
            <div
              onClick={() => {
                setcontent(3);
                console.log('hatha 3');
              }}
              className={`flex items-center justify-start text-gray-300 hover:bg-gray-700 py-2 px-6 cursor-pointer ${content===3 && "bg-gray-500"} `}
            >
              <GrCatalog  className="mr-4"/>

              <span>Brochures</span>
            </div>
            <div
              onClick={() => {
                setcontent(4);
                console.log('hatha 3');
              }}
              className={`flex items-center justify-start text-gray-300 hover:bg-gray-700 py-2 px-6 cursor-pointer ${content===4 && "bg-gray-500"} `}
            >
              <SiGooglemeet  className="mr-4"/>

              <span>Webinaire</span>
            </div>

          </>
        )}
      </nav>
      {/* Logout */}
      <div className="flex justify-center items-center mt-auto hover:bg-gray-700 py-4">
        <button
          onClick={() => {
            logout();
            // Handle logout logic here
            console.log('Logout clicked');
          }}
          className="flex items-center text-gray-300 py-2 px-6 cursor-pointer"
        >
          <CiLogout className="mr-4" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;