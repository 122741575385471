import React from 'react';
import Hero from '../Hero/Hero';
import Accueil from '../Accueil/Accueil';
import QuiSommesNous from '../QuiSommesNous/QuiSommesNous';
import Événements from '../Événements/Événements';
import Publications from '../Publications/Publications';
import Webinaires from '../Webinaires/Webinaires';
import Brochures from '../Brochures/Brochures';
import Contact from '../Contact/Contact';

const Home = () => {
  return (
    <div>
      <Hero />
      <Accueil renderHero={false} />
      <QuiSommesNous renderHero={false}/>
     
      <Événements renderHero={false}/>
      <Publications renderHero={false}/>
      <Webinaires renderHero={false}/>
      <Brochures renderHero={false}/>
      <Contact renderHero={false}/>
      
      {/* Ajouter un lien vers la page "RejoignezNous" */}
      
    </div>
  );
}

export default Home;
