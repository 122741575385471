import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry'; // Import correct du worker
import Hero from '../Hero/Hero';
import './Brochures.css';

const Brochures = ({ renderHero }) => {
  const pdfUrl = 'https://drive.google.com/file/d/1tX8cBCILBh4wEKiTbQiSKVQsYNznCERV/view?usp=sharing';
  const flipBookUrl = 'https://drive.google.com/file/d/1yaZSEacCu6j-29K3qUr_i896twhTGQgP/view?usp=sharing';
  const newFlipBookUrl = 'https://drive.google.com/file/d/1YC-WL4LaTv21gjKncb_PUWvCrzMCu5Z0/view?usp=sharing';

  const downloadPdf = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {renderHero && <Hero />}
      <div className='Brochures' id='Brochures'>
        <h1 style={{ color: 'grey', fontSize: '60px', marginBottom: '20px' }}>
          Brochures
        </h1>
        <div style={{ width: '2000px', height: '210px', marginBottom: '-9px' }}>
          <Worker workerUrl={pdfWorker}>
            <Viewer fileUrl={pdfUrl} />
          </Worker>
        </div>
        <button style={{ width: '900px' }} onClick={() => downloadPdf(pdfUrl, 'Rapport_moral_FTLT_2022.pdf')}>
          Télécharger Rapport moral FTLM 2022
        </button>
        <button style={{ width: '900px' }} onClick={() => downloadPdf(newFlipBookUrl, 'Rapport_moral_FTLT_2020-2021_Ar.pdf')}>
          Télécharger Rapport moral FTLM 2020-2021 Ar
        </button>
        <button style={{ width: '900px' }} onClick={() => downloadPdf(flipBookUrl, 'Rapport_moral_2019-2020.pdf')}>
          Télécharger Rapport moral 2019-2020
        </button>
      </div>
    </div>
  );
};

export default Brochures;
