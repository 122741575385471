import React, { useState, useEffect } from 'react';
import './Accueil.css';

import Hero from '../Hero/Hero';
import { Link } from "react-router-dom";


import fxImage from '../../assets/fx.png';
import drrrrImage from '../../assets/drrrr.png';
import fzzzImage from '../../assets/fzzz.png';
import zrrImage from '../../assets/zrr.png';
import ahmedImage from '../../assets/ahmed.png';
import ahmeddImage from '../../assets/ahmedd.png';

const Accueil = ({ renderHero }) => {

  const images = [
    drrrrImage ,
    fxImage,
    zrrImage,
    ahmeddImage,
    ahmedImage,
    fzzzImage,
  ];

 

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, []);

 

  return (
    <div>
      {renderHero && <Hero />}
      
      <Carousel slides={images} />
      </div>
  );
};

export default Accueil;


const Carousel = ({ autoSlide = true, autoSlideInterval = 2500, slides }) => {
  const [curr, setCurr] = useState(0);

  const next = () => setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

  useEffect(() => {
      if (autoSlide) {
          const slideInterval = setInterval(next, autoSlideInterval);
          return () => clearInterval(slideInterval);
      }
  }, [autoSlide, autoSlideInterval]);

  return (
 

      <div id="default-carousel" className="relative w-full" data-carousel="slide">
          <div className="relative w-full h-[90vh] overflow-hidden rounded-lg">
              {slides.map((slide, index) => (
                  <div
                      key={index}
                      className={`absolute block w-full h-full duration-700 ease-in-out ${index === curr ? 'block' : 'hidden'}`}
                      data-carousel-item
                  >
                      <img src={slide} className="absolute block w-full h-full object-cover" alt={`Slide ${index + 1}`} />
                  </div>
              ))}
          </div>


          <div className="centered-button">
        <p className="header-text">
        </p>
        <Link to="/RejoignezNous" className="nn">
          Rejoignez Nous
        </Link>
      </div>


          <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
              {slides.map((_, index) => (
                  <button
                      key={index}
                      type="button"
                      className={`w-3 h-3 rounded-full ${index === curr ? 'bg-blue-500' : 'bg-gray-500'}`}
                      aria-current={index === curr}
                      aria-label={`Slide ${index + 1}`}
                      data-carousel-slide-to={index}
                      onClick={() => setCurr(index)}
                  ></button>
              ))}
          </div>
        
          
      </div>
  );
};