import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const [role, setRole] = useState(null);
  const [email,setEmail]=useState(null)
  const [id, setId]=useState(null)


  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        
        setRole(decoded._doc?.role);
        setId(decoded._doc?._id)
        setEmail(decoded._doc?.email);
        console.log(decoded)
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("authToken");
        setToken(null);
        setRole(null);
        setId(null)
        setEmail();

      }
    }
  }, [token]);

  const login = (newToken) => {
    console.log(newToken);
    if (typeof newToken === "string") {
      setToken(newToken);
      localStorage.setItem("authToken", newToken);
      try {
        const decoded = jwtDecode(newToken);
        setRole(decoded._doc?.role);
        setEmail(decoded._doc?.email);
        setId(decoded._doc?._id)
        console.log(decoded);
      } catch (error) {
        console.error("Invalid token during login:", error);
        // Handle invalid token error
      }
    } else {
      console.error("Invalid token provided during login");
    }
  };

  const logout = () => {
    setToken(null);
    setRole(null);
    setId(null)
    localStorage.removeItem("authToken");
  };

  return (
    <AuthContext.Provider value={{ token, role,email ,id, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
