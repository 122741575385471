import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './Header.css';
import logo from '../../assets/ftlm23.png';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../auth/AuthContext";
import { jwtDecode } from "jwt-decode";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  // const [searchQuery, setSearchQuery] = useState(""); // Supprimé car non utilisé
  const navigate = useNavigate();
  const {token,logout,role,email}=useAuth();
  
  let userRole =null ;

  if (token) userRole=jwtDecode(token).role
  

  console.log(role)
  console.log(email)
  console.log(token)
  const handleClick = () => {
    navigate('/');
    setIsClicked(true);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  // const handleSearch = (e) => { // Supprimé car non utilisé
  //   if (e.key === 'Enter') {
  //     const lowerCaseQuery = searchQuery.toLowerCase();
  //     if (lowerCaseQuery === 'accueil' || lowerCaseQuery === 'home' || lowerCaseQuery === 'الرئيسية') {
  //       navigate('/Accueil');
  //     } else if (lowerCaseQuery === 'quisommesnous' || lowerCaseQuery === 'aboutus' || lowerCaseQuery === 'من نحن') {
  //       navigate('/QuiSommesNous');
  //     } else if (lowerCaseQuery === 'événements' || lowerCaseQuery === 'events' || lowerCaseQuery === 'الفعاليات') {
  //       navigate('/Événements');
  //     } else if (lowerCaseQuery === 'publications' || lowerCaseQuery === 'publications' || lowerCaseQuery === 'منشورات') {
  //       navigate('/Publications');
  //     } else if (lowerCaseQuery === 'webinaires' || lowerCaseQuery === 'webinars' || lowerCaseQuery === 'ندوات') {
  //       navigate('/Webinaires');
  //     } else if (lowerCaseQuery === 'brochures' || lowerCaseQuery === 'brochures' || lowerCaseQuery === 'كتيبات') {
  //       navigate('/Brochures');
  //     } else if (lowerCaseQuery === 'contact' || lowerCaseQuery === 'contact' || lowerCaseQuery === 'اتصال') {
  //       navigate('/Contact');
  //     }
  //   }
  // };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className={`header ${isClicked ? "clicked" : ""}`}>
      <div className="logo-container" onClick={handleClick}>
        <img src={logo} alt="Logo" className={`logo-img ${isClicked ? "clicked" : ""}`} />
      </div>

      <div className="social-links">
        <a href="https://www.facebook.com/ForumTunisiendesLeadersMaghrebines" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href="https://www.linkedin.com/in/forum-tunisien-des-leaders-maghr%C3%A9bines-a066721b3/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="https://www.instagram.com/ftlm.org.tn/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
      </div>

      <nav className="header-menu">
        <ul>
          <li>
            <Link to="/Accueil" className="nav-link">{t('accueil')}</Link>
          </li>
          <li>
            <Link to="/QuiSommesNous" className="nav-link">{t('QuiSommesNous')}</Link>
          </li>
          <li>
            <Link to="/Événements" className="nav-link">{t('événements')}</Link>
          </li>
          <li>
            <Link to="/Publications" className="nav-link">{t('publications')}</Link>
          </li>
          <li>
            <Link to="/Webinaires" className="nav-link">{t('webinaires')}</Link>
          </li>
          <li>
            <Link to="/Brochures" className="nav-link">{t('brochures')}</Link>
          </li>
        </ul>
      </nav>
      {userRole==="admin" ?<Link to="/Dashboard" className="button">
        {t('Dashboard')}
      </Link> :<Link to="/Contact" className="button">
        {t('contact')}
      </Link> }
      {token ?<button onClick={() => logout()} className="logout-button">Deconnecté</button>:<button onClick={() => navigate('/login')} className="login-button">Se Connecter</button> }
      

      <div className="language-selector">
        <button onClick={() => changeLanguage('en')}>EN</button>
        <button onClick={() => changeLanguage('fr')}>FR</button>
        <button onClick={() => changeLanguage('ar')}>AR</button>
      </div>
    </header>
  );
};

export default Header;


















