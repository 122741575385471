import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css';
import Hero from '../Hero/Hero';
import Typical from 'react-typical';
import ftmImage from '../../assets/ftm.png';

const Contact = ({ renderHero, userId }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    company: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const protocol = window.location.protocol;

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
    
      await axios.post(`${protocol}//ftlm.org.tn/contacts`, formData);
      toast.success('Message envoyé avec succès !');
      setFormData({
        fullName: '',
        email: '',
        phoneNumber: '',
        company: '',
        message: '',
      });
    //   await axios.get(`http://ftlm.org.tn/Contacts`)
    // .then(res=>console.log("******",res))
    // .catch(err=>console.log("==============",err));
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message :', error);
      toast.error('Échec de l\'envoi du message. Veuillez réessayer.');
    }
    
  };

  return (
    <div>
      <ToastContainer />

      {renderHero && <Hero />}

      <div className='Contact' id='Contact'>
        <div className="jj">
          <h1>
            <Typical
              loop={Infinity}
              steps={['Contactez-nous 💎', 3000]}
            />
          </h1>

          <form onSubmit={handleSubmit}>
            <label>
              Nom complet :
              <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
            </label>
            <br />
            <label>
              Email :
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </label>
            <br />
            <label>
              Numéro de téléphone :
              <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
            </label>
            <br />
            <label>
              Société :
              <input type="text" name="company" value={formData.company} onChange={handleChange} />
            </label>
            <br />
            <label>
              Votre message :
              <textarea name="message" value={formData.message} onChange={handleChange} required />
            </label>
            <br />
            <input type="hidden" name="userId" value={userId} />
            <button type="submit">Envoyer le message</button>
          </form>
        </div>

        <div className="ContactInfo">
          <h1 style={{ color: '#c30b0b' }}>Informations de contact</h1>
          <p>Forum Tunisien des Leaders Maghrébines FTLM</p>
          <p>Tél: <a href="tel:+21629868808" style={{ color: '#c30b0b' }}>+216 29 868 808</a></p>
          <p>Tél: <a href="tel:+21670685825" style={{ color: '#c30b0b' }}>+216 70685825</a></p>
          <p>E-mail: <a href="mailto:contact@ftlm.org.tn" style={{ color: '#c30b0b' }}>contact@ftlm.org.tn</a></p>
          <p>18 Rue John Keynes. Z.I Chotrana, la petite Ariana, Ariana, Tunisia</p>
        </div>
      </div>

      <div className='Contact' id='Contact'>
        <img
          src={ftmImage}
          alt={`Event 1`}
          className="image18"
          style={{ width: '100%', height: '800px' }}
        />
      </div>

      <div className="MapSection">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d204095.34511711085!2d10.007441714212609!3d36.93841176160801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12e2cbac6ccf4015%3A0x34b8c1a750a22703!2sFTLM!5e0!3m2!1sfr!2stn!4v1706607723542!5m2!1sfr!2stn"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps Location"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
