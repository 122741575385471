// MotDeLaPresidente.jsx
import React, { useState } from 'react';
import './PresentationFTLM.css';
import image0 from '../../assets/image55.png';
import tick from '../../assets/tick1.png';
import Hero from '../Hero/Hero';
import Typical from 'react-typical';

const PresentationFTLM = ({ renderHero }) => {
  const [showMoreText, setShowMoreText] = useState(false);

  const handleToggleText = () => {
    setShowMoreText(!showMoreText);
  };

  return (
    <div>
      {renderHero && <Hero />}
      <div className='PresentationFTLM' id='PresentationFTLM'>
        <div className='left-container'>
          <div className='left-r'>
            <div className='left-r'>
              <img src={image0} alt='' />
            </div>
          </div>
        </div>

        <div className={`right-container ${showMoreText ? 'expand' : ''}`}>
          <div>
            <h1 className='stroke'>
              <Typical
                loop={Infinity}
                steps={[
                  'Presentation',
                  3000,
                  'FTLM ❤️',
                  3000,
                 
                ]}
              />
            </h1>
          </div>
          <div className='details-r'>
            <div>
              <img src={tick} alt='' />
              <span className='text-block'>
                Le Forum Tunisien des Leaders Maghrébines FTLM est une association nationale indépendante créée à l'initiative de femmes expertes ayant l'ambition de contribuer activement au développement socio-économique de la Tunisie et ce dans un cadre d'échanges intermaghrébins.
              </span>
              <span className='text-block'>
                Son objectif majeur est de soutenir les activités des collectivités locales, de développer le sens civique auprès des citoyens et de contribuer à l'insertion socio-professionnelle des jeunes.
              </span>
              <span className='text-block'>
                Le FTLM ambitionne également à promouvoir l'entrepreneuriat féminin, à créer une synergie entre les Femmes Entrepreneures Maghrébines et à renforcer les capacités des entreprises à l'export.
              </span>
              <span className='text-block'>
                D'autre part, étant conscientes de l'importance du Digital, pour les années à venir, le FTLM est en train de fournir un effort de sensibilisation de la société civile aux pratiques Numériques et à l'intégration du Digital dans la vie professionnelle et personnelle. Dans cette perspective, notre association met à votre disposition un nouvel espace adapté à vos attentes. Nous sommes mobilisées à rassembler des Citoyennes Leaders du Maghreb autour de valeurs et objectifs communs.
              </span>
              {showMoreText && (
                <>
                  <span className='text-block'>
                    Soucieuse de conférer davantage d'efficience aux activités Socio-Économiques, le FTLM appelle toutes ses adhérentes à s'unir autour de leur jeune association et de contribuer à la réussite de ses programmes d'action.
                  </span>
                  <span className='text-block'>
                    Vous ne manquerez pas d'intérêt en visitant le site web du FTLM. Cela ne peut en être autrement du fait que nous existons pour vous.
                  </span>
                  <span className='text-block'>
                    Tout en espérant que les relations que nous entretenons soient renforcées davantage grâce à ce nouvel outil de communication, je vous invite à visiter notre site web.
                  </span>
                </>
              )}
              <button onClick={handleToggleText}>
                {showMoreText ? 'Lire Plus' : 'Lire Moins'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationFTLM;
