import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EventsTable = ({events, setEvents}) => {
    const protocol = window.location.protocol;
    const [editEvent, setEditEvent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [participates,setParticipates]=useState([]);
    

    useEffect(() => {
        
        axios.get(`${protocol}//ftlm.org.tn/evennements`)
            .then((res) => {
                setEvents(res.data);
            })
            .catch((err) => console.error(err));
    }, []);

    const handleRemove = (id) => {
    
        axios.delete(`${protocol}//ftlm.org.tn/evennements/${id}`)
            .then((res) => {
                setEvents(events.filter(course => course.id !== id));
            })
            .catch((err) => console.error(err));
    };

    const handleEdit = (course) => {
        axios.get(`${protocol}//ftlm.org.tn/evennements/${course.id}/participants`)
        .then (res=>{
            console.log(res.data);
            setParticipates(res.data);
        })
        .catch(err=>console.log(err))
        setEditEvent(course);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setEditEvent(null);
        setParticipates([])
        setShowModal(false);
    };

    const handleSave = () => {
        axios.patch(`${protocol}//ftlm.org.tn/evennements/${editEvent.id}`, editEvent)
            .then((res) => {
                setEvents(events.map(course => (course.id === res.data.id ? res.data : course)));
                handleCloseModal();
            })
            .catch((err) => {
                const errorMessage = err.response?.data?.message || 'An error occurred';
                alert(errorMessage);
            });
    };
    

    

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white text-center">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b-2 border-gray-300 ">Title</th>
                        <th className="py-2 px-4 border-b-2 border-gray-300 ">Description</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((course) => (
                        <tr key={course._id}>
                            <td className="py-2 px-4 border-b border-gray-300">{course.title}</td>
                            <td className="py-2 px-4 border-b border-gray-300">{course.description}</td>
                           
                            <td className="py-2 px-4 border-b border-gray-300 flex justify-center gap-3">
                                <button
                                    onClick={() => handleEdit(course)}
                                    className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                                >
                                    view
                                </button>
                                <button
                                    onClick={() => handleRemove(course.id)}
                                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={handleCloseModal}></div>
                        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                            <div className="px-4 py-5 sm:p-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">title : {editEvent.title} </h3>
                                <div className="mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Description : {editEvent.description} </h3>
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Lieu : {editEvent.lieu} </h3>
                                
                                </div>
                                <h1>participates</h1>
                                {participates.map((user,idx)=> <p key={idx}> {user.firstName} {user.lastName}</p>)}
                            </div>
                            <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
                               
                                <button
                                    onClick={handleCloseModal}
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventsTable;
