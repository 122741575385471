// ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./auth/AuthContext";
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to="/" />;
  }
  if(jwtDecode(token).role != "admin")    return <Navigate to="/" />;


  return children;
};

export default ProtectedRoute;
