import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../src/tailwind.css";

const WebinairesTable = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [titre, setTitre] = useState('');
  const [lien, setLien] = useState('');
  const [dateHeure, setDateHeure] = useState('');
  const [file, setFile] = useState(null);
  const protocol = window.location.protocol;

  const fetchWebinaires = async () => {
    try {
    
      
      const response = await axios.get(`${protocol}//ftlm.org.tn/webinaires`);
      const filePromises = response.data.map(async (file) => {
        const blobResponse = await axios.get(
          `${protocol}//ftlm.org.tn/webinaires/${file.id}/download`,
          { responseType: "blob" }
        );
        return {
          ...file,
          data: URL.createObjectURL(blobResponse.data),
        };
      });
      const filesWithBlobUrl = await Promise.all(filePromises);
      setMessages(filesWithBlobUrl);
    } catch (error) {
      console.error("Error fetching webinaires", error);
    }
  };

  useEffect(() => {
    fetchWebinaires();
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${protocol}//ftlm.org.tn/webinaires/${id}`)
      .then(() => {
        setMessages(messages.filter((message) => message.id !== id));
      })
      .catch((error) => {
        console.error("Error deleting message:", error);
      });
  };

  const handleView = (message) => {
    setSelectedMessage(message);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedMessage(null);
  };

  const openUploadModal = () => {
    setUploadModal(true);
  };

  const closeUploadModal = () => {
    setUploadModal(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUploadSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('titre', titre);
    formData.append('lien', lien);
    formData.append('dateHeure', dateHeure);
    formData.append('file', file);

    try {
      await axios.post(`${protocol}//ftlm.org.tn/webinaires`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Webinaire uploaded successfully');
      closeUploadModal();
      fetchWebinaires();
    } catch (error) {
      console.error('Error uploading webinaire', error);
      alert('Failed to upload webinaire');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between">
        <h2 className="text-2xl text-center font-bold mb-4">Webinaires</h2>
        <button
          onClick={openUploadModal}
          className="bg-green-500 text-white px-4 py-2 rounded-md w-1/6"
        >
          Add New Webinaire
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b-2 border-gray-300 text-center">
                Title
              </th>
              <th className="py-2 px-4 border-b-2 border-gray-300 text-center">
                Link
              </th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message) => (
              <tr key={message.id} className="min-w-full text-xl">
                <td className="py-2 px-4 border-b border-gray-300 text-center">
                  {message.titre}
                </td>
                <td className="py-2 px-4 border-b border-gray-300 text-center">
                  {message.lien}
                </td>
                <td className="py-2 px-4 border-b border-gray-300 text-center flex">
                  <button
                    onClick={() => handleView(message)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                  >
                    View
                  </button>
                  <button
                    onClick={() => handleDelete(message.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && selectedMessage && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={closeModal}></div>
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="px-4 py-5 sm:p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {selectedMessage.titre}
              </h3>
              <div className="mt-2">
                <p className="mb-3 text-gray-600">{selectedMessage.lien}</p>
                <p className="mb-3 text-gray-600">
                  {new Date(selectedMessage.dateHeure).toLocaleString()}
                </p>
                {selectedMessage.data && (
                  <img
                    src={selectedMessage.data}
                    alt={selectedMessage.titre}
                    className="max-w-full h-auto mb-3 rounded-md"
                  />
                )}
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                onClick={closeModal}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {uploadModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={closeUploadModal}></div>
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
            <div className="px-4 py-5 sm:p-6">
              <h2 className="text-2xl text-center font-bold mb-6 text-gray-800">Upload Webinaire</h2>
              <form onSubmit={handleUploadSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Titre</label>
                  <input
                    type="text"
                    value={titre}
                    onChange={(e) => setTitre(e.target.value)}
                    placeholder="Titre"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Lien</label>
                  <input
                    type="text"
                    value={lien}
                    onChange={(e) => setLien(e.target.value)}
                    placeholder="Lien"
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Date & Heure</label>
                  <input
                    type="datetime-local"
                    value={dateHeure}
                    onChange={(e) => setDateHeure(e.target.value)}
                    required
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Image</label>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept="image/*"
                    required
                    className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-green-500 text-white px-4 py-2 rounded-md"
                  >
                    Upload
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebinairesTable;
