import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Users from './Users';
import MessagesTable from './MessagesTable';
import EventForm from './EventForm';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import "../../../src/tailwind.css"
import Brochures from './Brochures';
import EventsTable from './EventsTable';
import WebinairesTable from './WebinairesTable';
import Dashboard from './DashboardHome';

function AdminDashboard() {
  const { token } = useAuth();
  const navigate = useNavigate();
  
  if (!token) navigate("/");
  
  const [events, setEvents] = useState([]);
  const [content, setContent] = useState(5); // Assuming content will be dynamic
  const [showModalAdd, setShowModalAdd] = useState(false);

  if (!token) return <Navigate to="/" />;

  const handleView = () => {
    setShowModalAdd(true);
  };

  const closeModal = () => {
    setShowModalAdd(false);
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="bg-gray-800 h-screen w-1/5 fixed left-0 top-0">
        <Sidebar setcontent={setContent} content={content} />
      </div>

      <div className="flex flex-col w-4/5 ml-auto">
        <div className="p-4">
          {content === 0 && <Users />}
          {content === 5 && <Dashboard />}

          {content === 1 && (
            <div className="text-center">
              <MessagesTable />
            </div>
          )}
          {content === 2 && (
            <div className="text-center">
              <div className="flex justify-between">
                All Events
                <button
                  onClick={handleView}
                  className="text-white bg-green-500 w-1/5 px-3 py-2 rounded-md text-base font-medium"
                >
                  Add Event
                </button>
              </div>
              <EventsTable events={events} setEvents={setEvents} />
            </div>
          )}
          {content === 3 && (
            <div className="text-center">
              <Brochures />
            </div>
          )}
          {content === 4 && (
            <div className="text-center">
              <WebinairesTable />
            </div>
          )}
          {showModalAdd && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10" onClick={closeModal}>
              <div className="bg-white p-6 rounded-md shadow-md w-full max-w-3xl" onClick={(e) => e.stopPropagation()}>
                <EventForm closeModal={closeModal} events={events} setEvents={setEvents} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
