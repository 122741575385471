import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './RegisterPage.css';


const RegisterAdmin = () => {
    const navigate = useNavigate();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');
    const protocol = window.location.protocol;

    const handleRegister = async (e) => {
        e.preventDefault();
        
        if (password !== confirm) {
            setError('Les mots de passe ne correspondent pas');
            return;
        }

        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('confirm', confirm);

        try {
           
           
            const response = await axios.post(`${protocol}//ftlm.org.tn/admin/register`, formData);
            if (response.status === 200) {
                navigate('/login'); // Redirect to login page after successful registration
            }
        } catch (error) {
            console.error('Error during registration:', error);
            setError('Erreur lors de l\'inscription');
        }
    };

    return (
        <div className='Publications' id='Publications'>
      
        <div className="register-page">
            <h2>Créer un compte</h2>
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleRegister}>
                <label>Prénom</label>
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
                <label>Nom</label>
                <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <label>Mot de passe</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <label>Confirmer le mot de passe</label>
                <input
                    type="password"
                    value={confirm}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <button type="submit" className="register-button">S'inscrire</button>
            </form>
        </div>
        </div>
      
    );
};

export default RegisterAdmin;
