import React, { useState } from 'react';
import './Comments.css';

const Comments = ({ onClose }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isActive, setIsActive] = useState(false);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (newComment.trim() !== '') {
      const comment = { text: newComment, reply: getAutoReply(newComment) };
      setComments([...comments, comment]);
      setNewComment('');
    }
  };

  const getAutoReply = (comment) => {
    if (comment.toLowerCase().includes('ftlm')) {
      return "Merci de votre intérêt pour le FTLM ! Le FTLM vise à renforcer les capacités des femmes entrepreneures à l'export et à développer le dialogue social.";
    } else {
      return 'Merci pour votre commentaire ! Nous allons le prendre en compte.';
    }
  };

  const toggleComments = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="comments-circle-container">
      <div className="comments-circle" onClick={toggleComments}>
        <span className="comments-icon">💬</span>
      </div>
      <div className={`comments-container ${isActive ? 'active' : ''}`}>
        <h3>Commentaires</h3>
        <form onSubmit={handleCommentSubmit}>
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Écrivez un commentaire..."
          />
          <button type="submit">Envoyer</button>
        </form>
        <ul>
          {comments.map((comment, index) => (
            <li key={index}>
              <p>{comment.text}</p>
              {comment.reply && <p className="reply">{comment.reply}</p>}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Comments;
