import React, { useState } from 'react';
import './QuiSommesNous.css';
import Hero from '../Hero/Hero';

const QuiSommesNous = ({ renderHero }) => {
  const [showMoreText] = useState(false);



  return (
    <div>
      {renderHero && <Hero />}
      
      <div className='Publications' id='Publications'>
        <div className='left-container'>
          <div className='org-chart'>
            <div className='org-chart-item president'>
              <p className='title'>Présidente</p>
              <p>Mme Raoudha Ben Saber</p>
            </div>
            <div className='org-chart-subordinates'>
              <div className='org-chart-item'>
                <p className='title'>Trésorière</p>
                <p>Mme Samia Ghorbel</p>
              </div>
              <div className='org-chart-item'>
                <p className='title'>Secrétaire Général</p>
                <p>Mme Kaouther Raach</p>
              </div>
            </div>
          </div>
        </div>

        <div className={`right-container ${showMoreText ? 'expand' : ''}`}>
          <div>
            <span className='stroke-text'>Qui Sommes-Nous</span>
          </div>
          <div className='details-r'>
            <div>
              <span style={{ textTransform: 'none', fontSize: '1.1rem' }}>
                Le Forum Tunisien des Leaders Maghrébines (FTLM) est une association nationale indépendante régie par le décret numéro 88 du 24 septembre 2011.
              </span>
              <span style={{ textTransform: 'none', fontSize: '1.1rem' }}>
                Le FTLM est créé à l'initiative de femmes expertes, autonomes et solidaires ayant l'ambition de contribuer activement au développement socio-économique de la Tunisie.
              </span>
              <span style={{ textTransform: 'none', fontSize: '1.1rem' }}>
                Le FTLM s'oriente vers le Maghreb pour lequel une vision globale régionale est aujourd'hui indispensable.
              </span>
              <span style={{ textTransform: 'none', fontSize: '1.1rem' }}>
                    Il envisage également la création d'un cadre de réflexion intermaghrébin autour des programmes de développement visant l'implication de la femme dans la mise en place des stratégies économiques, sociales et culturelles à l'échelle maghrébine.
                  </span>
             
            </div>
          </div>
        </div>
      </div>

      <div className='Publications' id='Publications'>

      <div className='objectives-section'>
      <img src='https://th.bing.com/th/id/OIP.Szl0ngGnkwZacWb9aVjbSwHaGK?rs=1&pid=ImgDetMain' alt='FTLM Banner' className='action-banner' />
      <h30>OBJECTIFS</h30>

  <div className='pentagonn-container'>
    <div className='pentagonn'>
    <p>Promouvoir l'entrepreneuriat féminin</p>    </div>
    <div className='pentagonn'>
    <p>Créer une synergie entre les femmes entrepreneures maghrébines</p>
    </div>
    <div className='pentagonn'>
    <p>Renforcer les capacités des entreprises à l'export</p>
    </div>
    <div className='pentagonn'>
    <p>Développer le sens de la citoyenneté chez les jeunes</p>
    </div>
    <div className='pentagonn'>
    <p>Encourager le dialogue des cultures et de l'appartenance</p>
    </div>
  </div>
</div>


<div className='objectives-section'>
<img src='https://www.cceibankbenin.com/images/2018/12/10/valeur_site.jpg' alt='FTLM Banner' className='actiont-banner' />
<h50>NOS PRINCIPES & NOS VALEURS</h50>

<div className='pentagonnt-container'>
  <div className='pentagonnt'>
    <p>Responsabilité</p>
  </div>
  <div className='pentagonnt'>
    <p>Solidarité - Travail de groupe</p>
  </div>
  <div className='pentagonnt'>
    <p>Tolérance – respect mutuel</p>
  </div>
  <div className='pentagonnt'>
    <p>Esprit d'ouverture et l'initiative</p>
  </div>
  <div className='pentagonnt'>
    <p>Mobilisation et persévérance</p>
  </div>
</div>

</div>
       



      </div>
      <div className='Publications' id='Publications'>
      <div className='action-section'>
        <img src='https://www.alexia.fr/img/articles/thumbs/size/675_0_10184_1611770226.jpg' alt='FTLM Banner' className='actionn-banner' />
        <h20>NOTRE DOMAINE D'ACTION</h20>
        </div>

        <div className='pentagon-container'>
          <div className='pentagon'>
            <p>Entreprenariat Féminin</p>
          </div>
          <div className='pentagon'>
            <p>Economie - création d'emplois</p>
          </div>
          <div className='pentagon'>
            <p>Environnement</p>
          </div>
          <div className='pentagon'>
            <p>Energies renouvelables</p>
          </div>
          <div className='pentagon'>
            <p>Sport - Education - Culture</p>
          </div>
          
        </div>
        
</div>

      
    </div>
    
  );
};

export default QuiSommesNous;
