import React from 'react';
import YouTube from 'react-youtube';
import Hero from '../Hero/Hero';
import { FacebookProvider, EmbeddedVideo } from 'react-facebook';
import './Publications.css';

const Video = ({ videoUrl }) => {
  if (videoUrl.includes('fb.watch')) {
    return (
      <div>
        <EmbeddedVideo href={videoUrl} width="500" />
      </div>
    );
  } else if (videoUrl.includes('youtube')) {
    return (
      <div>
        <YouTube videoId={videoUrl.split('v=')[1]} />
      </div>
    );
  } else {
    return null; // Handle other video platforms if necessary
  }
};

const Publications = ({ renderHero }) => {
  const youtubeVideoUrls = [
    'https://www.youtube.com/watch?v=cJkPVFxIDl8',
    'https://www.youtube.com/watch?v=4ekeREgeyzo',
  ];

  const facebookVideoUrls = [
    'https://fb.watch/qdW9x9G5ST/',
    'https://fb.watch/qdVl8GSMnP/',
  ];

  return (
    <div className="x">
      <div>
        {renderHero && <Hero />}
        <div className="Publications" id="Publications">
          {youtubeVideoUrls.map((videoUrl, index) => (
            <div key={index}>
              {videoUrl.includes('youtube') && <YouTube videoId={videoUrl.split('v=')[1]} />}
            </div>
          ))}
        </div>
        <div className="Publications" id="Publications">
          <FacebookProvider appId="Votre_App_ID">
            {facebookVideoUrls.map((videoUrl, index) => (
              <div key={index}>
                <Video videoUrl={videoUrl} />
              </div>
            ))}
          </FacebookProvider>
        </div>
      </div>
    </div>
  );
};

export default Publications;
